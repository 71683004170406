import axios from 'axios';
import Cookies from 'js-cookie';

export default class Service {
  /**
   * Creates an instance of Service.
   *
   * @memberOf Service
   */
  constructor(url = null) {
    const baseURL = url || process.env.VUE_APP_EI_SERVICE_ENDPOINT || '';
    // console.log(baseURL)
    this.axios = axios.create({
      baseURL: `${baseURL}/`,
      responseType: 'json'
    });
  }

  toQueries(params = {}) {
    const esc = encodeURIComponent;
    return Object.keys(params)
      .map(k => `${esc(k)}=${esc(params[k])}`)
      .join('&');
  }

  /**
   * Call a service action via REST API
   *
   * @param {any} action  name of action
   * @param {any} params  parameters to request
   * @returns  {Promise}
   *
   * @memberOf Service
   */

  async rest(
    action,
    params,
    options = {
      method: 'post'
    }
  ) {
    try {
      const token = (Cookies && Cookies.get('access_token')) || '';
      let headers = {};
      if (token) {
        headers['Authorization'] = `Bearer ${token}`;
      }
      const response = await this.axios.request(action, {
        method: options.method,
        headers,
        data: params
      });
      const { data } = response;
      return data;
    } catch (err) {
      // eslint-disable-next-line
      console.log('[axios] > [rest] > catch -> err', err);
      let error = err;
      if (err.response && err.response.data && err.response.data.error)
        error = err.response.data.error;
      throw error;
    }
  }

  get(action, params) {
    return this.rest(action, params, {
      method: 'get'
    });
  }

  update(action, params) {
    return this.rest(action, params, {
      method: 'put'
    });
  }
  remove(action, params) {
    return this.rest(action, params, {
      method: 'delete'
    });
  }
  async post(action, params) {
    // return this.rest(action, params, { method: 'post' });
    try {
      const response = await this.axios.request(action, {
        method: 'post',
        data: params
      });
      const { data } = response;
      return data;
    } catch (err) {
      console.log('[axios] > [rest] > catch -> err', err);
      // eslint-disable-next-line
      // let error = err;
      // if (
      //   err.response &&
      //   err.response.data &&
      //   err.response.data.data &&
      //   err.response.data.data.errors
      // )
      //   error = err.response.data.data.errors;
      // throw error;
      return null;
    }
  }
}
