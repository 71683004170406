<template>
  <div class="sidebar-wrapper">
    <AdminDashboard v-if="loginMode === LOGIN_MODE.ADMIN" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AdminDashboard from './components/admin.dashboard';
import { LOGIN_MODE } from 'core/constants';
export default {
  name: 'Dashboard',
  components: {
    AdminDashboard,
  },
  data() {
    return {
      LOGIN_MODE
    };
  },
  computed: {
    ...mapGetters('global', ['loginMode'])
  }
};
</script>
<style src="./index.scss" lang="scss" scoped />
