import Vue from 'vue';
import VueI18n from 'vue-i18n';

import en_US from './languages/i18n/en-US/translation.json';
import vi_VN from './languages/i18n/vi-VN/translation.json';

import element_locale from 'element-ui/lib/locale';
import element_vi_VN from 'element-ui/lib/locale/lang/vi';
import element_en_US from 'element-ui/lib/locale/lang/en';

Vue.use(VueI18n);

const messages = {
  'en-US': { ...en_US, ...element_en_US },
  'vi-VN': { ...vi_VN, ...element_vi_VN }
};

let defaultLanguage = 'en-US';
if (localStorage.getItem('language') != 'default') {
  defaultLanguage = localStorage.getItem('language');
}
export const i18n = new VueI18n({
  locale: defaultLanguage,
  fallbackLocale: 'vi_VN',
  messages, 
  silentFallbackWarn: true
});
element_locale.i18n((key, value) => {
  return i18n.t(key, value);
});
function setI18nLanguage(lang) {
  i18n.locale = lang;
  document.querySelector('html').setAttribute('lang', lang);
  return lang;
}
export function loadLanguageAsync(lang='default') {
  if (lang === 'default') {
    return Promise.resolve(setI18nLanguage(defaultLanguage));
  }
  if (i18n.locale !== lang) {
    return Promise.resolve(setI18nLanguage(lang));
  }
  return Promise.resolve(setI18nLanguage(lang));
}
