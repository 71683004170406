export const DEFAULT_TITLE = 'EI-ADMIN';

export const AUTH_LANGUAGE = 'user-language';
export const AUTH_USERNAME = 'user-name';
export const ACCESS_TOKEN = 'access_token';
export const REFRESH_TOKEN = 'refresh_token';
export const SELLERS_ADMINISTRATOR_NAME = 'SellersAdministrator';

export const LOGIN_MODE = {
  ADMIN: 'admin'
};
export const NAVBAR_TYPES = {
  STATIC: 'static',
  FLOATING: 'floating'
};

export const NAVBAR_COLOR_SCHEMES = {
  LIGHT: 'light',
  DARK: 'dark'
};

export const SIDEBAR_TYPES = {
  SOLID: 'solid',
  TRANSPARENT: 'transparent'
};

export const IMAGE_WIDTH_HEIGHT = {
  WIDTH: 1280,
  HEIGHT: 1280
};

export const SIDEBAR_COLOR_NAME = {
  DEFAULT: 'default', //'#002B49'
  WHITE: 'white', //'#FFFFFF'
  FIRST: 'first', //'#004472'
  SECOND: 'second', //'#e9ebef'
  THIRD: 'third', //'#d1e7f5'
  FOURTH: 'fourth', //'#ccdde9'
  FIFTH: 'fifth', //'#d6dfe6'
  SIXTH: 'sixth', //'#13191d'
  SEVENTH: 'seventh' //'#20ae8c'
};

export const UPLOAD_FILE_MODULE = {
  NEWS: 'News',
  PRODUCT: 'Product',
  CATEGORY: 'Category',
  CUSTOMER: 'Customer',
  USER: 'User',
  BASIC_INFO: 'BasicInfo',
  BRAND: 'Brand',
  SUPPLIER: 'Supplier',
  PAGE_CONTENTS: 'PagesContents',
  PARTNER: 'Partner',
  PAGE: 'Page',
  SUPPLIER_USER: 'SupplierUser',
  SELLER_TRAINING: 'SellerTraining',
  COLLABORATOR: 'Collaborator',
};

// STATUS ORDER

export const BAO_GIA = 1;
export const CHO_XAC_NHAN = 2;
export const DA_XAC_NHAN = 3;
export const CHO_KHACH_HANG_CHOT = 4;
export const CHO_KHACH_HANG_THANH_TOAN = 5;
export const DA_THANH_TOAN = 6;
export const DANG_GIAO_HANG = 7;
export const DA_NHAN_HANG = 8;
export const DA_HUY = 9;

export const CHECKOUT_STATUS = {
  [BAO_GIA]: 'Báo giá',
  [CHO_XAC_NHAN]: 'Chờ xác nhận',
  [DA_XAC_NHAN]: 'Đã xác nhận',
  [CHO_KHACH_HANG_CHOT]: 'Chờ khách hàng chốt',
  [CHO_KHACH_HANG_THANH_TOAN]: 'Chờ khách hàng thanh toán',
  [DA_THANH_TOAN]: 'Đã thanh toán',
  [DANG_GIAO_HANG]: 'Đang giao hàng',
  [DA_NHAN_HANG]: 'Đã nhận hàng',
  [DA_HUY]: 'Đã hủy'
};

export const CHECKOUT_COLOR = {
  [BAO_GIA]: 'status-grey',
  [CHO_XAC_NHAN]: 'status-orange',
  [DA_XAC_NHAN]: 'status-blue',
  [CHO_KHACH_HANG_CHOT]: 'status-violet',
  [CHO_KHACH_HANG_THANH_TOAN]: 'status-pink',
  [DA_THANH_TOAN]: 'status-black',
  [DANG_GIAO_HANG]: 'status-light-blue',
  [DA_NHAN_HANG]: 'status-green',
  [DA_HUY]: 'status-red'
};

// STATUS ORDER SUPPLIER

export const PO_BAO_GIA = 1;
export const PO_CHO_XAC_NHAN = 2;
export const PO_DA_XAC_NHAN = 3;
export const PO_DANG_GIAO_HANG = 4;
export const PO_DA_NHAN_HANG = 5;
export const PO_DA_HUY = 6;

export const ORDER_SUPPLIER_STATUS = {
  [PO_BAO_GIA]: 'Báo giá',
  [PO_CHO_XAC_NHAN]: 'Chờ xác nhận',
  [PO_DA_XAC_NHAN]: 'Đã xác nhận',
  [PO_DANG_GIAO_HANG]: 'Quá trình giao nhận',
  [PO_DA_NHAN_HANG]: 'Đã nhận hàng',
  [PO_DA_HUY]: 'Đã hủy'
};

export const ORDER_SUPPLIER_COLOR = {
  [PO_CHO_XAC_NHAN]: 'status-orange',
  [PO_DA_XAC_NHAN]: 'status-blue',
  [PO_DANG_GIAO_HANG]: 'status-black',
  [PO_DA_NHAN_HANG]: 'status-green',
  [PO_DA_HUY]: 'status-red'
};

// STATUS DEBT
export const DEBT_CHUA_YEU_CAU_THANH_TOAN = 1;
export const DEBT_YEU_CAU_THANH_TOAN = 2;
export const DEBT_XAC_NHAN_YEU_CAU = 3;
export const DEBT_THANH_TOAN_THANH_CONG = 4;
export const DEBT_CHUA_DUOC_DUYET = 5;

export const DEBT_STATUS = {
  [DEBT_CHUA_YEU_CAU_THANH_TOAN]: 'Chưa yêu cầu thanh toán',
  [DEBT_YEU_CAU_THANH_TOAN]: 'Yêu cầu thanh toán',
  [DEBT_XAC_NHAN_YEU_CAU]: 'Xác nhận yêu cầu thanh toán',
  [DEBT_THANH_TOAN_THANH_CONG]: 'Thanh toán nhà bán thành công',
  [DEBT_CHUA_DUOC_DUYET]: 'Chưa được duyệt'
};

export const DEBT_COLOR = {
  [DEBT_CHUA_YEU_CAU_THANH_TOAN]: 'secondary',
  [DEBT_YEU_CAU_THANH_TOAN]: 'info',
  [DEBT_XAC_NHAN_YEU_CAU]: 'primary-light',
  [DEBT_THANH_TOAN_THANH_CONG]: 'primary',
  [DEBT_CHUA_DUOC_DUYET]: 'dark'
};

//QUOTATION
export const QUOTATION_CHO_XAC_NHAN = 1;
export const QUOTATION_DA_XAC_NHAN = 2;
export const QUOTATION_DA_HUY = 3;
export const QUOTATION_STATUS_COLOR = {
  [QUOTATION_CHO_XAC_NHAN]: 'danger',
  [QUOTATION_DA_XAC_NHAN]: 'success',
  [QUOTATION_DA_HUY]: 'info'
};

export const QUOTATION_STATUS = {
  [QUOTATION_CHO_XAC_NHAN]: 'Chờ xác nhận',
  [QUOTATION_DA_XAC_NHAN]: 'Đã xác nhận',
  [QUOTATION_DA_HUY]: 'Đã Hủy'
};

export const QUOTATION_CANCEL_REASON_CODE = {
  ['OTHER_REASON']: 10
};

export const ACTIVE_MODULES = {
  ['pages']: 'pages',
  ['user']: 'user'
};

export const BANNER_MODULES = {
  ['BANNER_TITLE']: 'banner_title',
  ['BANNER_SUB_TITLE']: 'banner_subtitle'
};

// CUSTOMER
const CUSTOMER_ACTIVE = true;
const CUSTOMER_INACTIVE = false;

export const CUSTOMER_STATUS_COLOR = {
  [CUSTOMER_ACTIVE]: 'success',
  [CUSTOMER_INACTIVE]: 'info'
};

export const CUSTOMER_STATUS = {
  [CUSTOMER_ACTIVE]: 'Active',
  [CUSTOMER_INACTIVE]: 'Inactive'
};

export const LOCATION_TYPE = {
  ['DELIVERY_PLACE']: 'deliveryPlace',
  ['RECEIVE_PLACE']: 'receivePlace'
};

export const AVAILABLE_MARKETS = {
  ['VN_HAN']: {
    name: 'Hà Nội',
    code: 'VN_HAN'
  },
  ['VN_HPH']: {
    name: 'Hải Phòng',
    code: 'VN_HPH'
  },
  ['VN_SGN']: {
    name: 'Sài Gòn',
    code: 'VN_SGN'
  }
};

export const LALAMOVE_CODE = 'LALAMOVE';

export const SERVICE_TYPES_LALAMOVE = {
  ['MOTORCYCLE']: {
    description: 'Motorcycle',
    name: 'MOTORCYCLE',
    restrict: {
      length: 40,
      width: 40,
      height: 40,
      weight: 30
    },
    avaiableMarkets: ['VN_HAN', 'VN_SGN']
  },

  ['VAN']: {
    description: 'Van 500kg',
    name: 'VAN',
    restrict: {
      length: 120,
      width: 100,
      height: 120,
      weight: 500
    },
    avaiableMarkets: ['VN_HAN', 'VN_SGN']
  },

  ['VAN_1000']: {
    description: 'Van 1000kg',
    name: 'VAN_1000',
    restrict: {
      length: 190,
      width: 100,
      height: 120,
      weight: 1000
    },
    avaiableMarkets: ['VN_HAN', 'VN_SGN']
  },

  ['TRUCK175']: {
    description: 'Truck 500kg',
    name: 'TRUCK175',
    restrict: {
      length: 200,
      width: 120,
      height: 120,
      weight: 500
    },
    avaiableMarkets: ['VN_HPH', 'VN_HAN', 'VN_SGN']
  },

  ['TRUCK330']: {
    description: 'Truck 1000kg',
    name: 'TRUCK330',
    restrict: {
      length: 300,
      width: 160,
      height: 160,
      weight: 1000
    },
    avaiableMarkets: ['VN_HPH', 'VN_HAN', 'VN_SGN']
  },

  ['TRUCK550']: {
    description: 'Truck 2000kg',
    name: 'TRUCK550',
    restrict: {
      length: 430,
      width: 190,
      height: 200,
      weight: 2000
    },
    avaiableMarkets: ['VN_HAN', 'VN_SGN']
  },

  ['PICK_UP_TRUCK']: {
    description: 'Pickup Truck',
    name: 'PICK_UP_TRUCK',
    restrict: {
      length: 140,
      width: 150,
      height: 50,
      weight: 500
    },
    avaiableMarkets: ['VN_HAN', 'VN_SGN']
  }
};

export const ORDER_STATUS_LALAMOVE = {
  ['QUOTATION']: {
    description: 'In quotation time',
    name: 'QUOTATION'
  },

  ['ASSIGNING_DRIVER']: {
    description: 'Trying to match shipment with a driver',
    name: 'ASSIGNING_DRIVER'
  },

  ['ON_GOING']: {
    description: 'A driver has accepted the order',
    name: 'ON_GOING'
  },

  ['PICKED_UP']: {
    description: 'The driver has picked up the order',
    name: 'PICKED_UP'
  },

  ['COMPLETED']: {
    description: 'The order has been delivered sucessfully and transaction has concluded',
    name: 'COMPLETED'
  },

  ['CANCELED']: {
    description: 'User has canceled the order',
    name: 'CANCELED'
  },

  ['REJECTED']: {
    description: 'The order was matched and rejected twice by two drivers in a row',
    name: 'REJECTED'
  },

  ['EXPIRED']: {
    description: 'The order expired as no drivers accepted the order',
    name: 'EXPIRED'
  }
};

export const SEND_MAIL = 1;
export const DOWNLOAD_PO = 2;
export const SHOW_INFO = 3;
export const SHOW_TIMELINE = 4;
export const SEND_MESSAGE = 5;

export const orderSupplierActions = {
  [SHOW_INFO]: {
    icon: 'el-icon-document',
    title: 'src.modules.order.detail.btnAction.item.showInfo'
  },
  [SEND_MAIL]: {
    icon: 'el-icon-s-promotion',
    title: 'src.modules.order.detail.btnAction.item.sendMail'
  },
  [DOWNLOAD_PO]: {
    icon: 'el-icon-download',
    title: 'src.modules.order.detail.btnAction.item.downloadPO'
  },
  [SHOW_TIMELINE]: {
    icon: 'el-icon-alarm-clock',
    title: 'src.modules.order.detail.btnAction.item.showTimeline'
  },
  [SEND_MESSAGE]: {
    icon: 'el-icon-chat-dot-round',
    title: 'src.modules.order.detail.btnAction.item.sendMessage'
  }
};

export const SUP_AC_CONFIRM = 1;
export const SUP_AC_NO_CONFIRM = 2;
export const SUP_AC_CONTACT = 3;
export const SUP_AC_EDIT = 4;
export const SUP_AC_DELETE = 5;

export const INACTIVE_SUPPLIER_ACTIONS = {
  [SUP_AC_CONFIRM]: {
    icon: 'el-icon-check',
    title: 'Confirm'
  },
  [SUP_AC_NO_CONFIRM]: {
    icon: 'el-icon-close',
    title: 'No Confirm'
  },
  [SUP_AC_EDIT]: {
    icon: 'el-icon-edit',
    title: 'Edit'
  },
  [SUP_AC_DELETE]: {
    icon: 'el-icon-delete',
    title: 'Delete'
  }
};

export const STATUS_CONFIRM_SUPPLIER = [{
    name: 'Chờ xác nhận',
    value: 0,
    variant: 'secondary'
  },
  {
    name: 'Từ chối xác nhận',
    value: 1,
    variant: 'danger'
  }
];

export const SUPPLIER_MAIL_TEMPLATE_TYPE = {
  ['SUPPLIER_WAITING_CONFIRM']: '2',
  ['SUPPLIER_CONFIRMED']: '3',
  ['SUPPLIER_DELIEVERING']: '4',
  ['SUPPLIER_RECEIVED']: '5',
  ['SUPPLIER_CANCEL_ORDER']: '6'
};

export const SUPPLIER_MAIL_TEMPLATE_CODE = {
  ['SUPPLIER_WAITING_CONFIRM']: 'SupplierWaitingConfirm',
  ['SUPPLIER_CONFIRMED']: 'SupplierConfirmed',
  ['SUPPLIER_DELIEVERING']: 'SupplierDelivering',
  ['SUPPLIER_RECEIVED']: 'SupplierReceived',
  ['SUPPLIER_CANCEL_ORDER']: 'SupplierCancelOrder'
};
export const VCHR_TP = {
  ['DSCNT']: 'DSCNT',
  ['SHP_CD']: 'SHP_CD'
};
export const VCHR_DSCNT_TP = {
  ['CASH']: 'CASH',
  ['PRCNT']: 'PRCNT'
};
export const VCHR_CNDTN = {
  ['FREE_SHIP']: {
    ['CODE']: 'FREE_SHIP',
    ['NAME']: {
      en_US: 'Free Ship Amount and Area',
      vi_VN: 'MPVC với Trị Giá Hoá Đơn và Khu Vực',
    }
  }
};


export const availableLanguages = [{
    name: 'Tiếng Việt',
    code: 'vi-VN',
    src: '/images/flag-vietnam.png'
  },
  {
    name: 'English',
    code: 'en-US',
    src: '/images/flag-england.png'
  }
];

export const SUPPLIER_TYPE_CODE = {
  ['MARKETPLACE']: 'MRKTPLC',
  ['TRADING']: 'TRDNG',
  ['SOURCING']: 'SOURCING'
};

export const TEXT_NOT_FOUND = {
  vi_VN: 'Không có dữ liệu',
  en_US: 'No Data'
};

export const TYPE_TEMPLATE_MAIL_CODE = {
  ORDR_TMPLT_ML: 'ORDR_TMPLT_ML',
  QUO_TMPLT_ML: 'QUO_TMPLT_ML'
};

export const TYPE_TEMPLATE_FILE_QUOTATION_CODE = {
  QUO_PDF_TEMP: 'QuotationPDF',
};

export const DEFAULT_UNIT_NAME = {
  vi_VN: 'Cái',
  en_US: 'Piece'
};

export const CUSTOMER_FILTER_METHOD = {
  CUSTOMER_CODE: {
    label: 'CUSTOMER_CODE',
    value: 'CUSTOMER_CODE'
  },
  CUSTOMER_EMAIL: {
    label: 'CUSTOMER_EMAIL',
    value: 'CUSTOMER_EMAIL'
  },
  CUSTOMER_TAX_CODE: {
    label: 'CUSTOMER_TAX_CODE',
    value: 'CUSTOMER_TAX_CODE'
  },
  CUSTOMER_NAME: {
    label: 'CUSTOMER_NAME',
    value: 'CUSTOMER_NAME'
  },
  CUSTOMER_PHONE: {
    label: 'CUSTOMER_PHONE',
    value: 'CUSTOMER_PHONE'
  }
};