<template>
  <el-input 
    v-bind="mergedProps" 
    v-on="listeners" 
    :value="computedValue" 
    @input="onInput" 
    @focus="onFocus"
  > 
    
  <slot name="append" slot="append"></slot>
     
  </el-input>
</template>
<script>
export default {
  name: 'ei-input',
  inheritAttrs: false,
  props: {
    value: { type: [String, Number], default: null }
  },
  data() {
    return {
      isFocused: false,
      localValue: this.value
    };
  },
  computed: {
    computedValue: {
      get() {
        return this.localValue;
      },
      set(value) {
        this.$emit('input', value);
        this.localValue = value;
      }
    },
    listeners() {
      return {
        ...this.$listeners,
        blur: this.onBlur,
        focus: this.onFocus
      };
    },
    mergedProps() {
      return {
        ...this.$attrs,
        ...this.$props
      };
    }
  },
  watch: {
    value(value) {
      this.localValue = value;
    },
  },
  methods: {
    shouldUpdateLocalValue(localValue, checkVal, onBlur) {
      if (!onBlur) {
        if (this.isFocused) return false;
      }

      return localValue === checkVal;
    },
    onInput(event) {
      this.computedValue = event;
    },
    onBlur(event) {
      this.isFocused = false;
      this.$emit('blur', event);
      //force update local variable to value prop, same as $forceUpdate in v-model
      if (this.shouldUpdateLocalValue(this.localValue, this.value, true)) {
        this.localValue = this.value.trim(); // not computedValue
        this.computedValue = this.localValue;
      }
    },
    onFocus(event) {
      this.isFocused = true;
      this.$emit('focus', event);
    }
  }
};
</script>
