<template>
  <div class="sidebar-wrapper">
    <nav :class="{ sidebar: true, sidebarStatic, sidebarOpened, sidebarStatic: classhover }">
      <header class="logo">
        <router-link to="/app/dashboard"
          ><span class="primary-word"> <img :src="logo" :alt="'EI Industrial'" /></span> <span class="secondary-word"> EI Industrial</span></router-link
        >
      </header>

      <ul class="nav">
        <!-- Dashboard -->
        <NavLink :active-item="activeItem" :header="$t('src.components.AppLeftMenu.index.dashboard')" link="/app/dashboard" icon-name="flaticon-home" is-header />
        <!-- Order  -->
        <NavLink
          :permission="'order.read'"
          :active-item="activeItem"
          :header="$t('src.components.AppLeftMenu.index.order')"
          link="/app/order"
          icon-name="flaticon-layers-2"
          index="order"
          :children-links="[
            {
              header: $t('src.components.AppLeftMenu.index.order_management'),
              link: '/app/order',
              permission: 'order.read'
            },
            {
              header: $t('src.components.AppLeftMenu.index.productSetting'),
              link: '/app/order/setting',
              index: 'setting',
              permission: 'order-setting.read',
              childrenLinks: [
                {
                  header: $t('src.components.AppLeftMenu.index.orderStastus'),
                  link: '/app/order/setting/order-status',
                  permission: 'order-status.read'
                },
                {
                  header: $t('src.components.AppLeftMenu.index.mailTemplate'),
                  link: '/app/order/setting/mail-template',
                  permission: 'mail-template.read'
                }
              ]
            }
          ]"
        />
        <!-- Quotation -->
        <NavLink
          :permission="'quotation.read'"
          :active-item="activeItem"
          :header="$t('src.components.AppLeftMenu.index.quotation')"
          link="/app/quotation"
          icon-name="flaticon-quotation"
          index="quotation"
          label-color="success"
          :children-links="[
            {
              header: $t('src.components.AppLeftMenu.index.quotation_management'),
              link: '/app/quotation/management',
              permission: 'quotation.read'
            },
          ]"
        />
        <!-- Customers  -->
        <NavLink
          :permission="'customer.read'"
          :active-item="activeItem"
          :header="$t('src.components.AppLeftMenu.index.customer')"
          link="/app/customer"
          icon-name="flaticon-customer"
          index="customer"
          label-color="success"
          badge="9"
          :children-links="[
            {
              header: $t('src.components.AppLeftMenu.index.customer_management'),
              link: '/app/customer/management',
              permission: 'customer.read'
            },
            {
              header: $t('src.components.AppLeftMenu.index.product_repurchase'),
              link: '/app/product/repurchase',
              permission: 'productRepurchase.read'
            },
            {
              header: $t('src.components.AppLeftMenu.index.customer_notification'),
              link: '/app/customer/notification',
              permission: 'customer-notification.read'
            }
          ]"
        />
        <!-- Collaborator -->
        <NavLink
          :permission="'collaborator.read'"
          :active-item="activeItem"
          :header="$t('src.components.AppLeftMenu.index.collaborator')"
          link="/app/collaborator"
          icon-name="flaticon-supplier"
          index="collaborator"
          label-color="success"
          :children-links="[
            {
              header: $t('src.components.AppLeftMenu.index.collaborator_management'),
              link: '/app/collaborator/management',
              permission: 'collaborator.read'
            }
          ]"
        />
        <!-- My Account -->
        <NavLink :permission="'profile.read'" :active-item="activeItem" :header="$t('src.components.AppLeftMenu.index.my_account')" link="/app/my-account" icon-name="flaticon-person" is-header />
      </ul>
    </nav>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
// import { isScreen } from 'core/helpers';
import NavLink from './NavLink';
import logo from 'assets/images/logo.png';

export default {
  name: 'AppLeftMenu',
  components: { NavLink },
  props: {
    classhover: Boolean
  },
  data() {
    return {
      logo
    };
  },
  computed: {
    ...mapState('global', {
      sidebarStatic: (state) => state.sidebarStatic,
      sidebarOpened: (state) => !state.sidebarClose,
      activeItem: (state) => state.sidebarActiveElement
    })
  },
  created() {
    this.setActiveByRoute();
  },

  methods: {
    ...mapActions('global', ['changeSidebarActive', 'switchSidebar', 'toggleSidebar', 'setSidebarStatic']),
    setActiveByRoute() {
      this.setSidebarStatic(true);
      this.toggleSidebar();
      const paths = this.$route.fullPath.split('/');
      paths.pop();
      this.changeSidebarActive(paths.join('/'));
    }
  }
};
</script>

<!-- Sidebar styles should be scoped -->
<style src="./index.scss" lang="scss" scoped />
