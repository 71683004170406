<template>
  <div
    :class="[
      { root: true, sidebarClose, sidebarStatic },
      'sing-dashboard',
      'sidebar-' + sidebarColorName,
      'sidebar-' + sidebarType,
      { 'class-hover': classHover}
    ]"
  >
    <div class="left-menu" @mouseover="mouseOver" @mouseleave="mouseLeave">
      <AppLeftMenu :classhover="classHover"/>
    </div>
    <div class="wrap">
      <AppHeader />
      <v-touch class="content" :swipe-options="{ direction: 'horizontal' }">
        <transition name="router-animation">
          <router-view />
        </transition>
        <AppFooter />
      </v-touch>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import AppFooter from 'components/AppFooter';
import AppHeader from 'components/AppHeader';
import AppLeftMenu from 'components/AppLeftMenu';

export default {
  name: 'AppLayout',
  components: { AppFooter, AppHeader, AppLeftMenu },
  data() {
    return {
      classHover: false
    }
  },
  computed: {
    ...mapState('global', [
      'sidebarClose',
      'sidebarStatic',
      'sidebarColorName',
      'sidebarType',
      'helperOpened'
    ]),
    ...mapState('global', ['globalReady'])
  },
  created() {
    // const staticSidebar = JSON.parse(localStorage.getItem('sidebarStatic'));

    // if (staticSidebar) {
    //   this.setSidebarStatic(true);
    // } else if (!this.sidebarClose) {
    //   setTimeout(() => {
    //     this.switchSidebar(true);
    //     this.changeSidebarActive(null);
    //   }, 2500);
    // }

    this.switchSidebar(false);
    this.changeSidebarActive(null);

    this.handleWindowResize();
    window.addEventListener('resize', this.handleWindowResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleWindowResize);
  },
  methods: {
    ...mapActions('global', [
      'switchSidebar',
      'handleSwipe',
      'changeSidebarActive',
      'toggleSidebar',
      'toggleHelper',
      'setSidebarStatic'
    ]),
    handleWindowResize() {
      const width = window.innerWidth;

      if (width <= 768 && this.sidebarStatic) {
        this.toggleSidebar();
        this.changeSidebarActive(null);
      }
    },
    mouseOver() {
      if (!this.sidebarStatic) {
        this.classHover = true
        this.setSidebarStatic(true)
      }
    },
    mouseLeave() {
      this.classHover = false
      this.setSidebarStatic(false)
    }
  }
};
</script>

<style src="./index.scss" lang="scss" />
