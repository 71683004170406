<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <b-navbar class="app-header d-print-none" :class="[navbarTypeClass, 'header-' + navbarColorScheme]">
      <b-nav>
        <b-nav-item id="v-step-0">
          <a class="d-md-down-none px-2" href="#" @click="toggleSidebarMethod">
            <i class="fi flaticon-menu" />
          </a>
          <a class="fs-lg d-lg-none" href="#" @click="switchSidebarMethod">
            <i class="fi flaticon-menu" />
          </a>
        </b-nav-item>
      </b-nav>
      <b-nav>
        <b-nav-item>
          <breadcrumb />
        </b-nav-item>
      </b-nav>
      <a class="navbarBrand d-md-none">
        <i class="fa fa-circle text-primary mr-n-sm" />
        <i class="fa fa-circle text-danger" />
        sing
        <i class="fa fa-circle text-danger mr-n-sm" />
        <i class="fa fa-circle text-primary" />
      </a>
      <b-nav class="ml-auto">
        <!-- <b-nav-item-dropdown
        @hide="dropdown = false"
        @show="dropdown = true"
        id="v-step-1"
        class="notificationsMenu d-md-down-none mr-2"
        menu-class="notificationsWrapper py-0 animate__animated animate__animated-fast animate__fadeIn"
        right
      >
        <template slot="button-content">
          <span class="avatar rounded-circle thumb-sm float-left mr-2">
            <img
              v-if="user.avatar || user.email"
              class="rounded-circle"
              :src="user.avatar || avatarImage"
              :alt="user.username"
            />
            <span v-else>{{ firstUserLetter }}</span>
          </span>
          <span>{{ user.username || user.email || 'Loading...' }}</span>
          <span
            class="ml-2 mr-2 circle badge-dark text-white fw-bold"
            style="padding: 13px 13px;"
            >user</span
          >
        </template>
        <Notifications />
      </b-nav-item-dropdown> -->
        <b-nav class="btn-edit" v-show="loginMode === LOGIN_MODE.SUPPLIER">
          <button class="btn-edit">
            <a target="_blank" :href="EI_ENDPOINT + shopLink">
              <i class="el-icon-shopping-cart-full" />
              {{ $i18n.t('src.components.AppHeader.goToTheStore') }}
            </a>
          </button>
        </b-nav>
        <!-- <b-nav-item>
          <b-nav class="btn-edit" v-show="loginMode === LOGIN_MODE.SUPPLIER">
            <el-dropdown @command="handleCommand">
              <span class="el-dropdown-link">
                Dropdown List<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-for="language in availableLanguages"
                  :key="language.name"
                  :value="language.code"
                >
                  <div>
                    <img
                      :key="language.name"
                      :src="language.src"
                      class="image"
                      style="max-height: 20px"
                    />
                    <span>{{ language.name }}</span>
                  </div>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </b-nav>
        </b-nav-item> -->
        <b-nav-item-dropdown
          @hide="dropdown = false"
          @show="dropdown = true"
          id="v-step-1"
          class="notificationsMenu d-md-down-none mr-2"
          menu-class="notificationsWrapper py-0 animate__animated animate__animated-fast animate__fadeIn"
          right
        >
          <template slot="button-content">
            <span class="avatar rounded-circle thumb-sm float-left mr-2">
              <img v-if="user.avatar || user.email" class="rounded-circle" :src="user.avatar ? this.S3_STORE_ENDPOINT + '/' + user.avatar : avatarImage" :alt="user.username" />
              <span v-else>{{ firstUserLetter }}</span>
            </span>
            <span>{{ user.username || user.email || 'Loading...' }}</span>
            <!-- <span class="ml-2 mr-2 circle badge-dark text-white fw-bold" style="padding: 13px 13px">{{ totalNotificationUnRead || 0 }}</span>
            <i :class="['fi flaticon-arrow-down px-2 dropdown-arrow', { active: dropdown }]" /> -->
          </template>
          <!-- <Notifications /> -->
        </b-nav-item-dropdown>
        <b-nav-item-dropdown id="v-step-2" class="settingsDropdown d-sm-down-none" no-caret right>
          <template slot="button-content">
            <i class="fi flaticon-settings-10 px-3" />
          </template>
          <!-- <b-dropdown-item href="/inbox">
          <b-badge
            variant="dark"
            pill
            class="animate__animated animate__bounceIn"
            style="padding: 2px 3px;                    position: absolute;                    font-size: 7px;"
            >9</b-badge
          >
          <i class="fi flaticon-email px-3 mr-3" />Notifications
        </b-dropdown-item>
        <b-dropdown-divider /> -->

          <!-- Upload (S) -->
          <!-- <el-upload
          ref="uploadFiles"
          accept=".xlsx,.xls"
          action=""
          multiple
          :auto-upload="false"
          :show-file-list="false"
          :on-change="handleImportData"
        >
          <b-dropdown-item-button>
            <i class="fi flaticon-import-data px-3 mr-3" />
            Import Data
          </b-dropdown-item-button>
          <b-dropdown-divider />
        </el-upload> -->
          <!-- Upload (E) -->

          <!-- <b-dropdown-item-button>
          <i class="fi flaticon-export-data px-3 mr-3" />
          Export Data
        </b-dropdown-item-button>
        <b-dropdown-divider /> -->

          <b-dropdown-item-button @click="logout"> <i class="fi flaticon-power-1 px-3 mr-3" /> Log Out </b-dropdown-item-button>
        </b-nav-item-dropdown>
        <b-nav-item-dropdown id="v-step-2" class="settingsDropdown d-sm-down-none" no-caret right>
          <template slot="button-content">
            <img :src="logoLanguage" class="image" style="height: 15px; width: 20px" />
            <i class="el-icon-arrow-down el-icon--right"></i>
          </template>
          <b-dropdown-item-button @click="hanldChangeLanguage(language.code)" v-for="language in availableLanguages" :key="language.name" :value="language.code">
            <div>
              <img :key="language.name" :src="language.src" class="image" style="height: 15px; width: 20px; margin-right: 10px" />
              &ensp;
              <span>{{ language.name }}</span>
            </div>
          </b-dropdown-item-button>
        </b-nav-item-dropdown>
      </b-nav>
    </b-navbar>
    <div v-if="isDemo && isSupplier && pathName !== '/app/road-to-verify'" class="demo-warning">
      Bạn đang trải nghiệm giao diện người dùng ở chế độ xem trước. &ensp;&ensp;
      <el-button type="primary" size="medium" @click="$router.push('/app/road-to-verify')" class="btn-blue-md mb-0">Kích hoạt ngay </el-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { mapState, mapActions } from 'vuex';
import avatarImage from 'assets/images/avatar/no-avatar.jpg';
import Breadcrumb from 'components/Breadcrumb';
import { ACTIVE_MODULES } from 'core/constants';
import { convertExcelToJson } from 'core/helpers';
import { AUTH_LANGUAGE, LOGIN_MODE, availableLanguages } from 'core/constants';
import { loadLanguageAsync } from 'locales';
// import Notifications from '@/components/Notifications/Notifications';

export default {
  name: 'AppHeader',
  components: {
    Breadcrumb,
    // Notifications
  },
  data() {
    return {
      EI_ENDPOINT: process.env.VUE_APP_EI_ENDPOINT,
      S3_STORE_ENDPOINT: process.env.VUE_APP_S3_STORE_ENDPOINT,
      availableLanguages,
      avatarImage,
      dropdown: false,
      LOGIN_MODE,
      logoLanguage: ''
      // user: JSON.parse(localStorage.getItem('user')) || {
      //   name: 'admin@ei.com',
      //   email: 'admin@ei.com'
      // }
    };
  },

  computed: {
    ...mapGetters('global', ['loginMode']),
    ...mapState('global', ['sidebarClose', 'sidebarStatic', 'navbarType', 'navbarColorScheme']),
    ...mapState('page', ['pages', 'listPage']),
    ...mapState('auth', ['user']),
    ...mapState('supplier', ['supplierInfo']),
    ...mapState('notification', ['totalNotificationUnRead']),

    pathName() {
      return window.location.pathname;
    },

    shopLink() {
      if (this.isDemo) {
        return (this.supplierInfo && this.supplierInfo.slug && `/${this.supplierInfo.demoCode}/cua-hang/${this.supplierInfo.slug}`) || '';
      }
      return (this.supplierInfo && this.supplierInfo.slug && `/cua-hang/${this.supplierInfo.slug}`) || '';
    },

    isDemo() {
      const { certification } = this.supplierInfo || { certification: '' };
      if (certification === 'DEMO') return true;
      return false;
    },

    isSupplier() {
      return this.user.supplierId == null ? false : true;
    },

    firstUserLetter() {
      return (this.user.name || this.user.email || 'P')[0].toUpperCase();
    },
    navbarTypeClass: function () {
      return 'navbar-' + this.navbarType + '-type';
    }
  },

  async created() {
    // set language
    const findLanguage = availableLanguages.find((item) => item.code == localStorage.getItem(AUTH_LANGUAGE));
    this.logoLanguage = findLanguage?.src || availableLanguages[0].src;
    // catch getMe
    if (Object.keys(this.user).length === 0) {
      await this.getMe();
    }
    if (this.user.supplierId && this.user.supplierId > 0) await this.getSupplierInfo(this.user.supplierId);
  },

  methods: {
    ...mapActions('global', ['toggleSidebar', 'switchSidebar', 'changeSidebarActive', 'setGlobalReady']),
    ...mapActions('page', ['importData']),
    ...mapActions('auth', ['logoutUser', 'getMe']),
    ...mapActions('supplier', ['getSupplierInfo']),

    hanldChangeLanguage(lang) {
      const findLanguage = availableLanguages.find((item) => item.code == lang);
      this.logoLanguage = findLanguage?.src || availableLanguages[0].src;
      localStorage.setItem(AUTH_LANGUAGE, lang);
      loadLanguageAsync(lang);
    },

    switchSidebarMethod() {
      if (!this.sidebarClose) {
        this.switchSidebar(true);
        this.changeSidebarActive(null);
      } else {
        this.switchSidebar(false);
        const paths = this.$route.fullPath.split('/');
        paths.pop();
        this.changeSidebarActive(paths.join('/'));
      }
    },
    async logout() {
      const data = await this.logoutUser();
      if (data.type == 'success') {
        // this.$router.push({ name: 'Login' });
        window.location.href = '/login';
      }
    },
    toggleSidebarMethod() {
      if (this.sidebarStatic) {
        this.toggleSidebar();
        this.changeSidebarActive(null);
      } else {
        this.toggleSidebar();
        const paths = this.$route.fullPath.split('/');
        paths.pop();
        this.changeSidebarActive(paths.join('/'));
      }
    },

    /**
     *
     * @param {Object} file
     */
    handleImportData(file) {
      const path = this.$route.fullPath.split('/');
      let activeModule;
      path[path.length - 1] === 'management' ? (activeModule = path[path.length - 2]) : (activeModule = path[path.length - 1]);

      this.setGlobalReady(false);

      if (ACTIVE_MODULES[activeModule] === 'pages') {
        convertExcelToJson(file)
          .then(async (item) => {
            const data = await this.importData(item);
            if (data.type !== false) {
              this.$notify({
                title: this.$i18n.t('common.notify.title.success'),
                message: this.$i18n.t('src.modules.pages.index.saveSuccess'),
                type: 'success'
              });
            } else {
              this.$notify({
                title: this.$i18n.t('common.notify.title.error'),
                message: `${data.message}`,
                type: 'error'
              });
            }
          })
          .catch((error) => {
            this.$message.error(`${error.message}`);
            return false;
          });
      }

      this.setGlobalReady(true);
    }
  }
};
</script>

<style src="./index.scss" lang="scss">
</style>
