var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sidebar-wrapper"},[_c('nav',{class:{ sidebar: true, sidebarStatic: _vm.sidebarStatic, sidebarOpened: _vm.sidebarOpened, sidebarStatic: _vm.classhover }},[_c('header',{staticClass:"logo"},[_c('router-link',{attrs:{"to":"/app/dashboard"}},[_c('span',{staticClass:"primary-word"},[_c('img',{attrs:{"src":_vm.logo,"alt":'EI Industrial'}})]),_vm._v(" "),_c('span',{staticClass:"secondary-word"},[_vm._v(" EI Industrial")])])],1),_c('ul',{staticClass:"nav"},[_c('NavLink',{attrs:{"active-item":_vm.activeItem,"header":_vm.$t('src.components.AppLeftMenu.index.dashboard'),"link":"/app/dashboard","icon-name":"flaticon-home","is-header":""}}),_c('NavLink',{attrs:{"permission":'order.read',"active-item":_vm.activeItem,"header":_vm.$t('src.components.AppLeftMenu.index.order'),"link":"/app/order","icon-name":"flaticon-layers-2","index":"order","children-links":[
          {
            header: _vm.$t('src.components.AppLeftMenu.index.order_management'),
            link: '/app/order',
            permission: 'order.read'
          },
          {
            header: _vm.$t('src.components.AppLeftMenu.index.productSetting'),
            link: '/app/order/setting',
            index: 'setting',
            permission: 'order-setting.read',
            childrenLinks: [
              {
                header: _vm.$t('src.components.AppLeftMenu.index.orderStastus'),
                link: '/app/order/setting/order-status',
                permission: 'order-status.read'
              },
              {
                header: _vm.$t('src.components.AppLeftMenu.index.mailTemplate'),
                link: '/app/order/setting/mail-template',
                permission: 'mail-template.read'
              }
            ]
          }
        ]}}),_c('NavLink',{attrs:{"permission":'quotation.read',"active-item":_vm.activeItem,"header":_vm.$t('src.components.AppLeftMenu.index.quotation'),"link":"/app/quotation","icon-name":"flaticon-quotation","index":"quotation","label-color":"success","children-links":[
          {
            header: _vm.$t('src.components.AppLeftMenu.index.quotation_management'),
            link: '/app/quotation/management',
            permission: 'quotation.read'
          } ]}}),_c('NavLink',{attrs:{"permission":'customer.read',"active-item":_vm.activeItem,"header":_vm.$t('src.components.AppLeftMenu.index.customer'),"link":"/app/customer","icon-name":"flaticon-customer","index":"customer","label-color":"success","badge":"9","children-links":[
          {
            header: _vm.$t('src.components.AppLeftMenu.index.customer_management'),
            link: '/app/customer/management',
            permission: 'customer.read'
          },
          {
            header: _vm.$t('src.components.AppLeftMenu.index.product_repurchase'),
            link: '/app/product/repurchase',
            permission: 'productRepurchase.read'
          },
          {
            header: _vm.$t('src.components.AppLeftMenu.index.customer_notification'),
            link: '/app/customer/notification',
            permission: 'customer-notification.read'
          }
        ]}}),_c('NavLink',{attrs:{"permission":'collaborator.read',"active-item":_vm.activeItem,"header":_vm.$t('src.components.AppLeftMenu.index.collaborator'),"link":"/app/collaborator","icon-name":"flaticon-supplier","index":"collaborator","label-color":"success","children-links":[
          {
            header: _vm.$t('src.components.AppLeftMenu.index.collaborator_management'),
            link: '/app/collaborator/management',
            permission: 'collaborator.read'
          }
        ]}}),_c('NavLink',{attrs:{"permission":'profile.read',"active-item":_vm.activeItem,"header":_vm.$t('src.components.AppLeftMenu.index.my_account'),"link":"/app/my-account","icon-name":"flaticon-person","is-header":""}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }