
<template>
   <footer class="contentFooter">
        <!-- Sing App Vue Admin Dashboard Template - Made by <a href="https://flatlogic.com" rel="nofollow noopener noreferrer" target="_blank">Flatlogic</a> -->
   </footer>
</template>

 <script>
 export default {
   name: 'AppFooter'    
 }
 </script>

