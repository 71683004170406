<template>
  <li v-if="!childrenLinks && isHeader" :class="{ headerLink: true, className }">
    <div v-can="permission">
      <div @click="() => resizeWiget()">
        <router-link :to="link" class="sidebar-link">
          <span class="icon">
            <i :class="fullIconName"></i>&nbsp; 
            <div class="content-item">
              <div :class="hoverClass" ref="busstop">
                <a class="hover-header"><strong>{{ header }}</strong></a>
              </div>
            </div>
          </span>
          {{ header }}
          <sup v-if="label" :class="'text-' + labelColor" class="headerLabel">{{
            label
          }}</sup>
          <b-badge v-if="badge" variant="info" pill>{{ badge }}</b-badge>
        </router-link>
      </div>
    </div>
  </li>
  <li v-else-if="childrenLinks" :class="{ headerLink: true, className }">
    <div v-can="permission">
      <div @click="() => togglePanelCollapse(link)">
        <router-link :to="link" event="" class="d-flex sidebar-link">
          <span class="icon">
            <i :class="fullIconName"></i>&nbsp;
            <div class="content-item">
              <div :class="hoverClass" ref="busstop">
                <a class="hover-header"><strong>{{ header }}</strong></a>
                <ul class="sub-menu">
                  <NavLink
                    v-for="(childrenLink, i) in childrenLinks"
                    :active-item="activeItem"
                    :header="childrenLink.header"
                    :index="childrenLink.index"
                    :link="childrenLink.link"
                    :permission="childrenLink.permission"
                    :children-links="childrenLink.childrenLinks"
                    :key="childrenLink.link + 'a' + i"
                  />
                </ul>
              </div>
            </div>
          </span>
          <span class="label-parent">{{ header }}</span>
          <sup v-if="label" :class="'text-' + labelColor" class="ml-1 headerLabel">{{
            label
          }}</sup>
          <div :class="{ caretWrapper: true, carretActive: isActive }">
            <i class="fa fa-angle-right" />
          </div>
        </router-link>
      </div>
      <b-collapse :id="'collapse' + index" :visible="isActive">
        <ul class="sub-menu">
          <NavLink
            v-for="(childrenLink, i) in childrenLinks"
            :active-item="activeItem"
            :header="childrenLink.header"
            :index="childrenLink.index"
            :link="childrenLink.link"
            :permission="childrenLink.permission"
            :children-links="childrenLink.childrenLinks"
            :key="childrenLink.link + 'b' + i"
          />
        </ul>
      </b-collapse>
    </div>
  </li>
  <li v-else>
    <div v-can="permission">
      <router-link :to="index !== 'menu' && link">
        <span class="label-child">{{ header }}</span>
        <sup v-if="label" :class="'text-' + labelColor" class="headerLabel">{{
          label
        }}</sup>
      </router-link>
    </div>
  </li>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'NavLink',
  props: {
    badge: { type: String, default: '' },
    header: { type: String, default: '' },
    iconName: { type: String, default: '' },
    headerLink: { type: String, default: '' },
    link: { type: String, default: '' },
    childrenLinks: { type: Array, default: null },
    className: { type: String, default: '' },
    isHeader: { type: Boolean, default: false },
    deep: { type: Number, default: 0 },
    activeItem: { type: String, default: '' },
    label: { type: String, default: '' },
    labelColor: { type: String, default: 'warning' },
    index: { type: String, default: '' },
    permission: { type: String, default: '' }
  },
  data() {
    return {
      headerLinkWasClicked: true,
      hoverClass: 'dropdown-content'
    };
  },

  computed: {
    ...mapState('global', ['sidebarClose', 'sidebarStatic']),
    fullIconName() {
      return `fi ${this.iconName}`;
    },
    isActive() {
      return (
        this.activeItem &&
        this.activeItem.includes(this.index) &&
        this.headerLinkWasClicked
      );
    }
  },

  methods: {
    ...mapActions('global', [
      'toggleSidebar',
      'switchSidebar',
      'changeSidebarActive',
      'setGlobalReady'
    ]),
    ...mapActions('auth', ['logoutUser']),

    togglePanelCollapse(link) {
      this.changeSidebarActive(link);
      this.headerLinkWasClicked =
        !this.headerLinkWasClicked || !this.activeItem?.includes(this.index) || false;

      if (!this.sidebarStatic) {
        const paths = this.$route.fullPath.split('/');
        paths.pop();
        this.changeSidebarActive(paths.join('/'));
      }
    },

    resizeWiget() {
      if (!this.sidebarStatic) {
        const paths = this.$route.fullPath.split('/');
        paths.pop();
        this.changeSidebarActive(paths.join('/'));
      }
    },
  }
};
</script>

<style src="./index.scss" lang="scss" scoped />
