<template>
  <main>
    <vue-element-loading
      :active="!globalReady"
      :is-full-screen="true"
      spiner="line-scale"
    />
    <router-view :key="$route.path" />
  </main>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'App',

  components: {},
  data() {
    return {};
  },

  computed: {
    ...mapState('global', ['globalReady'])
  },
  watch: {},

  methods: {}
};
</script>
<style lang="scss">
@import 'styles/theme.scss';
@import 'styles/app.scss';
</style>
