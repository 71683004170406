import moment from 'moment';
// import { IMAGE_WIDTH_HEIGHT } from 'core/constants';
// test permission staging
import XLSX from 'xlsx';

const screens = {
  'xs-max': 543,
  'sm-min': 544,
  'sm-max': 767,
  'md-min': 768,
  'md-max': 991,
  'lg-min': 992,
  'lg-max': 1199,
  'xl-min': 1200
};

export const isScreen = size => {
  const screenPx = window.innerWidth;
  return (
    (screenPx >= screens[`${size}-min`] || size === 'xs') &&
    (screenPx <= screens[`${size}-max`] || size === 'xl')
  );
};

export const formattedDate = time => {
  return moment(time).format('DD/MM/YYYY');
};

export const convertToSlug = str => {
  str = str.toLowerCase()
  str = str.replace(/(à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ)/g, 'a')
  str = str.replace(/(è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ)/g, 'e')
  str = str.replace(/(ì|í|ị|ỉ|ĩ)/g, 'i')
  str = str.replace(/(ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ)/g, 'o')
  str = str.replace(/(ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ)/g, 'u')
  str = str.replace(/(ỳ|ý|ỵ|ỷ|ỹ)/g, 'y')
  str = str.replace(/(đ)/g, 'd')
  str = str.replace(/\\`|\\~|\\!|\\@|\\#|\||\$|\\%|\^|\\&|\*|\(|\)|\+|\\=|\\,|\.|\/|\?|\\>|\\<|\\'|\\"|\\:|\\;/g,
    '')
  str = str.replace(/!([^0-9a-z-_\s])/g, '')
  str = str.replace(/_/g, '-')
  str = str.replace(/(\s+)/g, '-')
  str = str.replace(/(-+)/g, '-')
  str = str.replace(/^-+/g, '')
  str = str.replace(/-+$/g, '')
  return str
};

export const convertToAlphabet = str => {
  str = str.toLowerCase();
  str = str.replace(/(à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ)/g, 'a');
  str = str.replace(/(è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ)/g, 'e');
  str = str.replace(/(ì|í|ị|ỉ|ĩ)/g, 'i');
  str = str.replace(/(ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ)/g, 'o');
  str = str.replace(/(ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ)/g, 'u');
  str = str.replace(/(ỳ|ý|ỵ|ỷ|ỹ)/g, 'y');
  str = str.replace(/(đ)/g, 'd');
  // Xóa ký tự đặc biệt
  // eslint-disable-next-line no-useless-escape
  str = str.replace(/\`|\~|\!|\@|\#|\||\$|\%|\^|\&|\*|\(|\)|\+|\=|\,|\.|\/|\?|\>|\<|\'|\"|\:|\;/g, '');

  str = str.replace(/!([^0-9a-z-_\s])/g, '');
  // Xóa khoảng trắng thay bằng ký tự -
  str = str.replace(/(\s+)/g, ' ');
  // Xóa khoảng trắng thay bằng ký tự -
  str = str.replace(/(-+)/g, ' ');
  // xóa phần dự - ở đầu
  str = str.replace(/^-+/g, '');
  // xóa phần dư - ở cuối
  str = str.replace(/-+$/g, '');
  // return
  return str;
};

export const filterByKey = (data, field, searchKey) => {
  return data.filter(item =>
    convertToAlphabet(item[field].toLocaleLowerCase()).includes(convertToAlphabet(searchKey.toLocaleLowerCase()))
  )
};

export const checkDuplicate = (list, item, old) => {
  // check data duplicate
  const {
    name,
    email,
    taxCode,
    code
  } = item;
  if (name && name != old) {
    const duplicate = list.find(obj => obj.name == name); // check name duplicate
    if (duplicate) {
      return true;
    } else {
      return false;
    }
  }
  if (email && email != old) {
    const duplicate = list.find(obj => obj.email == email); // check email duplicate
    if (duplicate) {
      return true;
    } else {
      return false;
    }
  }
  if (taxCode && taxCode != old) {
    const duplicate = list.find(obj => obj.taxCode == taxCode); // check tax code duplicate
    if (duplicate) {
      return true;
    } else {
      return false;
    }
  }
  if (code && code != old) {
    const duplicate = list.find(obj => obj.code == code); // check tax code duplicate
    if (duplicate) {
      return true;
    } else {
      return false;
    }
  }
};

export const validateStock = (rule, value, callback) => {
  var validate = /^[0-9]+$/;
  if (Number(value) >= -3 && Number(value) <= -1) callback();
  if (validate.test(value) == false && value !== null && value !== '') {
    callback('Stock incorrect');
  } else {
    callback();
  }
};

export const validateEmail = (rule, value, callback) => {
  // check email calidate
  const validate = /^(([^<>()[\]\\.,;:\s@|"]+(\.[^<>()[\]\\.,;:\s@|"]+)*)|(|".+|"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (validate.test(value) == false && value !== null && value !== '') {
    callback('Email incorrect');
  } else {
    callback();
  }
};

export const validatePhone = (rule, value, callback) => {

  if (value && value.length > 0) {
    // check phone number validate
    var validate = /(^(\+)?)([0-9]*)$/;

    if (validate.test(value) == false) {
      callback('Phone Number incorrect');
    } else {
      callback();
    }

  } else {
    callback();
  }

};

export const validatePrice = (rule, value, callback) => {
  // check price validate
  var validate = /^[0-9]+$/;

  if (validate.test(value) == false && value !== null && value !== '') {
    callback('Price incorrect');
  } else {
    callback();
  }
};

export const validateNumber = (rule, value, callback) => {
  // check price validate
  var validate = /^[0-9]+$/;

  if (validate.test(value) == false && value !== null && value !== '') {
    callback('Number incorrect');
  } else {
    callback();
  }
};

export const validateDouble = (rule, value, callback) => {
  // check price validate
  var validate = /^[0-9]+([.][0-9]+)?$/;
  if (validate.test(value) == false && value !== null && value !== '') {
    callback('Number incorrect');
  } else {
    callback();
  }
};

export const validatePercent = (rule, value, callback) => {
  if ((parseFloat(value) >= 0 && parseFloat(value) <= 100) || value == 0) {
    callback();
  } else {
    callback('Percent incorrect');
  }
};

export const validateTaxCode = (rule, value, callback) => {
  // check tax code (mã số thuế) validate
  // var validate = /((01|02|03|04|05|06|07|08|09|10|11|12|13|14|15|16|17|18|19|20|21|22|24|25|26|27|28|29|30|31|32|33|34|35|36|37|38|39|40|41|42|43|44|45|46|47|48|49|50|51|52|53|54|55|56|57|58|59|60|61)+([0-9]{8})\b)/g;
  var validate = /^.{7,13}$/;
  if (!validate.test(value)) {
    callback('Tax Code incorrect');
  } else {
    callback();
  }
};

export const validateDateSmallerThenToday = (rule, value, callback) => {
  const CurrentDate = new Date();
  const GivenDate = new Date(value);
  if (GivenDate < CurrentDate) {
    callback('Given date is greater than the current date.');
  } else {
    callback();
  }
};

export const asyncImgChecked = file => {
  return new Promise(async (resolve, reject) => {
    try {
      const isImg =
        file.raw.type === 'image/jpeg' ||
        file.raw.type === 'image/png' ||
        file.raw.type === 'image/jpg' ||
        file.raw.type === 'image/gif';
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isImg) {
        return reject({
          status: false,
          message: 'Pictures can only be in JPG/PNG/JPG/GIF format!'
        });
      }

      if (!isLt2M) {
        return reject({
          status: false,
          message: 'The picture size cannot exceed 2MB!'
        });
      }
      return resolve({
        status: true
      });
      // const width = IMAGE_WIDTH_HEIGHT.WIDTH;
      // const height = IMAGE_WIDTH_HEIGHT.HEIGHT;

      // let reader = new FileReader();
      // reader.readAsDataURL(file.raw);

      // reader.onload = () => {
      //   const img = new Image();
      //   img.src = reader.result;
      //   if (img.complete) {
      //     // if it exists in the browser cache
      //     const valid = img.width * img.height <= height * width;
      //     if (!valid) {
      //       console.log('img.height: ', img.height);
      //       console.log('img.width: ', img.width);
      //       return reject({
      //         status: false,
      //         message: 'The width and height of the uploaded picture must <= 720*720!'
      //       });
      //     }
      //     return resolve({
      //       status: true
      //     });
      //   } else {
      //     img.onload = () => {
      //       const valid = img.width * img.height <= height * width;
      //       if (!valid) {
      //         console.log('img.height: ', img.height);
      //         console.log('img.width: ', img.width);
      //         return reject({
      //           status: false,
      //           message: 'The width and height of the uploaded picture must <= 720*720!'
      //         });
      //       }
      //       return resolve({
      //         status: true
      //       });
      //     };
      //   }
      // };
    } catch (error) {
      console.log('error check image before upload: ', error);
      return reject({
        status: false,
        message: error.message
      });
    }
  });
};

export const convertPermissions = permissions => {
  let result = [];
  permissions.map(async permission => {
    const arrActions = permission.action.split('|');
    arrActions.map(action => {
      let data = permission.module.code + '.';
      data += action;
      result.push(data);
    });
  });
  return result;
};

export const preventSpace = object => {
  Object.keys(object)
    .map(key => object[key] = typeof object[key] == 'string' ?
      object[key].trim() :
      object[key]
    );
};

export const convertExcelToJson = file => {
  return new Promise(async (resolve, reject) => {
    try {
      const isExcel =
        file.raw.type ===
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
        file.raw.type === 'application/vnd.ms-excel';
      if (!isExcel) {
        return reject({
          status: false,
          message: 'The file can only be in .xlsx/.xls format!'
        });
      }
      const reader = new FileReader();
      reader.onload = function (e) {
        /* Parse data */
        const data = e.target.result;
        const workbook = XLSX.read(data, {
          type: 'binary'
        });
        /* Get first worksheet */
        const workbookName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[workbookName];
        const result = XLSX.utils.sheet_to_json(worksheet);
        resolve(result);
      };
      reader.readAsBinaryString(file.raw);
    } catch (error) {
      return reject({
        status: false,
        message: error.message
      });
    }
  });
};

export const sortArray = array => {
  array.sort((firstItem, secondItem) => {
    if (firstItem < secondItem) {
      return -1;
    }
    if (firstItem > secondItem) {
      return 1;
    }
    return 0;
  });
}

export const readThree = (num, index) => {
  const numLst = [
    ' không ',
    ' một ',
    ' hai ',
    ' ba ',
    ' bốn ',
    ' năm ',
    ' sáu ',
    ' bảy ',
    ' tám ',
    ' chín '
  ];
  const unit = [' trăm ', ' mươi ', ''];
  let result = '';
  let a = num[0],
    b = num[1],
    c = num[2];
  if (a === '0' && b === '0' && c === '0') return '';

  // xu li hang tram
  if (a === '0') {
    if (index !== 0) result = result + numLst[0] + unit[0];
  } else {
    result = result + numLst[a] + unit[0];
  }

  // xu li hang chuc
  if (b === '0') {
    if (c !== '0' && (index !== 0 || a !== '0')) result += 'lẻ';
  } else if (b === '1') {
    result += 'mười';
  } else result = result + numLst[b] + unit[1];

  // xu li hang don vi
  if (c === '5') {
    if (b !== '0') result += ' lăm ';
    else result += ' năm ';
  } else if (c === '1') {
    if (b !== '0' && b !== '1') result += ' mốt ';
    else result += ' một ';
  } else if (c !== '0') result = result + numLst[c];

  return result;
};

export const convertNumberToWord = (number) => {
  const moneyUnit = [
    ' triệu tỷ ',
    ' nghìn tỷ ',
    ' tỷ ',
    ' triệu ',
    ' nghìn',
    ''
  ];
  let needZeroCount = number.toString().length % 3;
  if (needZeroCount !== 0) needZeroCount = 3 - needZeroCount;
  number = '0'.repeat(needZeroCount) + number;
  const devideNumber = [];
  for (let i = 0; i < number.length / 3; i++) {
    let c = number.substr(i * 3, 3);
    devideNumber.push(c);
  }
  let result = '';
  devideNumber.forEach((item, index) => {
    let c = readThree(item, index);
    if (c !== '') {
      c += moneyUnit[moneyUnit.length - devideNumber.length + index];
    }
    result += c;
  });
  const formatResult = result.trim().replace(/\s+/g, ' ');
  return formatResult.charAt(0).toUpperCase() + formatResult.slice(1);
};

export const formattedNumber = (num) => {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

export const formatDate = (date, fulldate = true, days = 0) => {
  let d = addDaysToDate(date, days),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear(),
    hour = d.getHours(),
    minute = d.getMinutes(),
    second = d.getSeconds();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;
  return fulldate ?
    `${[day, month, year].join('-')} lúc ${[hour, minute, second].join(':')}` :
    [day, month, year].join('-');
}

export const addDaysToDate = (date, days) => {
  var res = new Date(date);
  res.setDate(res.getDate() + days);
  return res;
}

export const validateStringPreventDuplicateWhiteSpace = (rule, value, callback) => {

  const stringTrim = value.trim();

  if (stringTrim.length === 0) {
    callback(new Error('Please input'));
  } else {
    callback();
  }
};

export const validatePassword = (rule, value, callback) => {
  // Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character
  if (value && value.length > 0) {
    const stringTrim = value.trim();
    const regex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;

    if (regex.test(stringTrim) === false) {
      callback(new Error('Incorrect password format'));
    } else {
      callback();
    }
  } else {
    callback();
  }

};

export const generateCode = (length) => {
  var result = '';
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789abcdefghijklmnopqrstuvwxyz';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}